import {useEffect, useMemo, useState} from 'react'

import {faTimes} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {useGPT} from '../../core/ads/GPTContext'
import {createAd, getAdSpecs} from '../../core/ads/utils'
import styles from '../../styles/StickyFooter.module.scss'

export default function StickyFooterAd({adCode}: {adCode: string}) {
  const adSpecs = useMemo(() => getAdSpecs(adCode, 'stickyFooter'), [adCode])
  const {id} = adSpecs

  const [dismissed, setDismissed] = useState(false)
  const [adLoaded, setAdLoaded] = useState(false) // can't dismiss the component entirely otherwise google can't find the div
  const {GPTHasLoaded} = useGPT()

  // Create ad and pass onAdRendered callback
  useEffect(() => {
    if (GPTHasLoaded && !dismissed) {
      return createAd(adSpecs, GPTHasLoaded, undefined, () => setAdLoaded(true))
    }
  }, [adSpecs, GPTHasLoaded, dismissed, id])

  if (dismissed) {
    return <></>
  }

  return (
    <div
      className={`fixed-bottom justify-content-center ${styles.outerContainer}`}
      id={`${id}-container`}
      style={{
        backgroundColor: adLoaded ? 'rgba(0,0,0, 0.4)' : 'transparent',
      }}
    >
      <div
        className={`${styles.innerContainer} d-flex flex-column flex-lg-row align-items-end align-items-lg-start`}
      >
        <div className="order-1 order-lg-0" id={id} />
        {adLoaded && (
          <FontAwesomeIcon
            icon={faTimes}
            role="button"
            onClick={() => setDismissed(true)}
            className={`border bg-white ${styles.closeButton} order-0 order-lg-1`}
          />
        )}
      </div>
    </div>
  )
}
